.nav-bar {
  position: fixed;
  width: 250px;
  padding: 10px;
  top: 0;
  bottom: 0;
  background-color: white;
  border-right: 1px solid #ecf1f2;
  font-weight: 400;
  list-style: none;
  z-index: 900;
}

.nav-bar .nav-link {
  display: flex;
  flex-direction: row;
  text-decoration: none;
  align-items: center;
  font-size: 13px;
  margin: 2px;
  padding: 8px;
  color: #141719;
  background-color: #F6F8FA;
  border-radius: 8px;
  transition: all 0.3s;
}

.nav-bar .nav-icon {
  margin-right: 8px;
}

.nav-bar .nav-link.collapsed {
  color: #525866;
  background-color: white;
}

.nav-bar .nav-link:hover {
  color: #0c2a5a;
  background-color: #dbecff;
}