@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');
html {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Lexend', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F8F9FC !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.no-decor { text-decoration: none; }

.login-header {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgb(255, 255, 255);
  padding: 7px 15px;
}

.header {
  display: flex;
  flex-direction: column;
  position: fixed;
  justify-content: space-between;
  height: 50px;
  left: 250px;
  right: 0;
  padding-top: 7px;
  background-color: white;
  border-bottom: solid 1px #d2e5ea;
  z-index: 901;
}

.main-page {
  display: flex;
  margin-top: 50px;
}

.main-page-settings {
  display: flex;
  margin-top: 50px;
}

.body-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 250px;
}

.body-page-settings {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 250px;
}

.head-long-page {
  display: flex;
  flex-direction: row;
  position: fixed;
  align-items: center;
  left: 250px;
  right: 0;
  justify-content: space-between;
  background-color: rgb(255, 255, 255);
  padding: 5px 10px;
  box-shadow: 0 1px 5px 0 rgba(82, 63, 105, 0.2);
  z-index: 800;
}

.right-nav-menu {
  display: flex;
  flex-direction: column;
  width: 280px;
  padding: 8px;
  background-color: #ffffff;
  z-index: 950;
}

.status-active {
  width: 8px;
  height: 8px;
  background-color: springgreen;
}

.flag-icon {
  width: 55px;
  height: 25px;
}

.card-item-count {
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  border-radius: 8px;
  border: #e7eaef 1px solid;
}

.btn-hover {
  transition: all 0.3s;
}

.btn-hover:hover {
  cursor: pointer;
  background-color: rgba(247, 249, 250, 0.402);
}

.button-u {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #3d3d3d;
  border-radius: 50px !important;
  font-weight: 600;
  font-size: 13px !important;
  padding: 10px 15px;
  transition: all 0.3s;
  border: none;
}

.table-th-cell {
  padding: 4px 10px !important;
  border-right: #9ca1aa 1px solid;
}

.table-cell {
  padding: 4px 10px !important;
  border-bottom: #e7eaef 1px solid;
}

.card-item-count {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 16px 20px;
  border-radius: 8px;
  border: #cfd6d9 1px solid;
}

.user-avatar {
  height: 35px;
  width: 35px;
  border: #74848d 2px solid;
  border-radius: 100px;
  object-fit:scale-down;
}

.person-photo {
  height: 120px;
  width: 120px;
  border: #afafaf 1px solid;
  object-fit:scale-down;
}

.bank-logo {
  width: 60px;
  height: 60px;
  background-color: rgb(236, 240, 244);
  border-radius: 100px;
  border: solid 1px rgb(198, 207, 211);
}

.bank-logo-sm {
  width: 30px;
  height: 30px;
  background-color: rgb(236, 240, 244);
  border-radius: 100px;
  border: solid 1px rgb(244, 246, 247);
}

.country-flag {
  width: 50px;
  height: 40px;
  border-radius: 3px;
  border: 1px solid #BBB;
  background-color: #ffffff;
}

.country-flag-sm {
  width: 20px;
  height: 15px;
  border-radius: 4px;
}

.country-flag-countainer {
  display: flex;
  align-items: center;
  width: 35px;
  height: 35px;
  padding: 6px;
  border: 1px solid #d0d7dc;
  border-radius: 100px;
}

.country-flag-circle {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100px;
}

.image-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 120px;
  width: 120px;
  background-color: #ffffff;
  border:#1f4d6c 2px dashed;
  border-radius: 4px;
}

.upload-btn {
  border-radius: 10px;
  padding: 2px 15px;
  transition: .5s;
  border: 1px dashed #BBB;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
}

.container-found-list-items {
  max-height: 250px;
  overflow-y: scroll;
  position: absolute;
  z-index: 901;
  box-shadow: 0 5px 5px 0 rgba(82, 63, 105, 0.2);
}

@keyframes zoom {
  from {transform: scale(0.1)} 
  to {transform: scale(1)}
}

.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover, .close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

@media only screen and (max-width: 700px){
  .modal-content {
    width: 100%;
  }
}

.lang-item {
  background-color: rgb(230, 236, 239) !important;
  border-radius: 4px;
}

.lang-item:hover {
  background-color: rgb(22, 83, 158) !important;
  color: white !important;
}

.hover-cursor { cursor: pointer; }

.show { display: block; }

.hide { display: none; }

.valid { color: green; }

.invalid { color: red; }


/* scrollbar */
::-webkit-scrollbar { width: 10px; }

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px #e7eaee; 
  border-radius: 2px;
  border: #cfd5db 1px solid;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(173, 190, 200); 
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #7a8da1; 
  border: #6c88a7 1px solid;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
    appearance: textfield;
}