.dropdown-menu {
    border-radius: 4px;
    padding: 5px 0 !important;
    animation-name: dropdown-animate;
    animation-duration: 0.2s;
    animation-fill-mode: both;
    border: rgb(177, 187, 194) 1px solid !important;
    box-shadow: 0 5px 10px 0 rgba(66, 66, 66, 0.2);
    z-index: 999;
}

.dropdown-menu .dropdown-item {
    font-size: 12px;
    padding: 3px 10px !important;
    transition: 0.3s;
}

.dropdown-menu .dropdown-item:hover {
    background-color: #e2f7fd;
}

@media (min-width: 768px) {
    .dropdown-menu-arrow::before {
        content: "";
        width: 13px;
        height: 13px;
        background: #fff;
        position: absolute;
        top: -7px;
        right: 20px;
        transform: rotate(45deg);
        border-top: 1px solid #eaedf1;
        border-left: 1px solid #eaedf1;
    }
}

.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}

@keyframes dropdown-animate {
    0% { opacity: 0; }
    100% { opacity: 1; }
    0% { opacity: 0; }
}